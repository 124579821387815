import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
  NavLink,
  useParams,
} from 'react-router-dom';
import { SET_CONFIG } from '../../../actions/types';
import { getStructuresAction, getStructureAction } from '../../../actions/structuresActions';
import CustomSelect from '../../lib/Select';

import styles from './FormNav.module.scss';
import { yearsOptions } from '../../../constants/constants';

export default function FormNav() {
  const { config, structuresList, structure } = useSelector((state) => state.structures);
  const { userAuth, role } = useSelector((state) => state.auth);
  const structureId = useParams()?.structure;
  const dispatch = useDispatch();
  const history = useHistory();

  const getStructures = useCallback(() => {
    getStructuresAction(dispatch);
  }, [dispatch]);

  function handleChangeConfig(key, value) {
    dispatch({
      type: SET_CONFIG,
      payload: { ...config, [key]: value },
    });
  }

  useEffect(() => {
    getStructures();
  }, []);

  useEffect(() => {
    if (!config.structure && structuresList?.length > 0) {
      const data = {
        ...config,
        structure: structureId || structuresList[0]._id,
        year: yearsOptions[1],
      };
      dispatch({
        type: SET_CONFIG,
        payload: data,
      });
    }
  }, [structuresList]);

  useEffect(() => {
    if (config.structure) {
      history.replace({ pathname: `/forms/${config.structure}` });
      getStructureAction(dispatch, config.structure);
    }
  }, [config.structure]);

  return (
    <div className={`${styles.container} ${config.structure ? styles.active : ''}`}>
      <div className={styles.select}>
      {config.structure && structuresList?.length > 1
        && <CustomSelect
          options={structuresList?.map((s) => ({ label: s.name, value: s._id }))}
          value={config.structure}
          handleChange={(val) => handleChangeConfig('structure', val)}
          />
      }
      {config.structure && userAuth?.role !== 'Admin'
        && <h4>{structuresList[0].name}</h4>
      }
      </div>
      <div className={styles.select}>
      {config.structure
        && <CustomSelect
          options={yearsOptions?.map((y) => ({ label: y, value: y.toString() }))}
          value={config.year}
          handleChange={(val) => handleChangeConfig('year', val)}
          />
      }
      </div>
      {role === 'Admin'
        && <div className={styles.provisional}>
          <p className={`${!config.isProvisional ? styles.active : ''}`}>Réel</p>
          <label className={styles.toggle}>
            <input type="checkbox" checked={!!config.isProvisional} onChange={() => handleChangeConfig('isProvisional', !config.isProvisional)}/>
            <div className={styles['toggle-control']}></div>
          </label>
          <p className={`${config.isProvisional ? styles.active : ''}`}>Prévisionnel</p>
        </div>
      }
      <nav>
        <NavLink
          to={`/forms/${structure?._id}`}
          activeClassName={styles['active-link']}>
          Chiffres d’affaire
        </NavLink>
        <NavLink
          to={`/forms/frequentation/${structure?._id}`}
          activeClassName={styles['active-link']}>
          Les fréquentations
        </NavLink>
        <NavLink
          to={`/forms/consumption/${structure?._id}`}
          activeClassName={styles['active-link']}
          >
          Les consommations énergétiques
        </NavLink>
        <NavLink
          to={`/forms/expense/${structure?._id}`}
          activeClassName={styles['active-link']}
          >
          Les dépenses en GER
        </NavLink>
        <NavLink
          to={`/forms/filling/${structure?._id}`}
          activeClassName={styles['active-link']}
          >
          Les taux de remplissage des activités
        </NavLink>
        <NavLink
          to={`/forms/payroll/${structure?._id}`}
          activeClassName={styles['active-link']}
          >
          Les dépenses en masse salariale
        </NavLink>
      </nav>
    </div>
  );
}
